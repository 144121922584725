import React, { useEffect, useState } from 'react'; 
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NavBar from '../../../component/navbar';
import './meu-perfil.css';
import firebase from '../../../config/firebase';
import {ReturnIdade, ReturnData} from '../../../util/datas';

function MeuPerfil() {
    const [nome, setNome] = useState();
    const [date, setDate] = useState();
    const [idade, setIdade] = useState();
    const [endereco, setEndereco] = useState();

    const usuario = useSelector(state => state.usuarioId); 
    const email = useSelector(state => state.usuarioEmail); 
    useEffect(()=>{
        firebase.firestore().collection('usuarios').doc(usuario).get().then( resultado => {
            setNome(resultado.data().nome);
            setDate(ReturnData(new Date(resultado.data().date)));
            setIdade(ReturnIdade( resultado.data().date));
            setEndereco(resultado.data().endereco);

        })
    })
    return(
        <>
            <NavBar active="meuperfil" />
            <main id="cad-perfil" className="main p-lg-5 p-md-3 p-sm-1 row m-0">
                <div className="container d-block">
                    <h1>{nome}</h1>
                    <hr class="separator solid " />
                    {/* <span className="d-block"><strong className="text-success">Data Nascimento: </strong >{date}<strong className="text-success">   Idade: </strong> {idade} anos</span> */}
                    
                    
                    <span className="d-block">
                        <strong className="text-success">E-mail: </strong >{email} 
                        
                    </span>
                    <span className="d-block">
                        <strong className="text-success">Endereço: </strong >{endereco}
                    </span>
                </div>
            </main>
        </>
    );
}

export default MeuPerfil;